@font-face {
  font-family: 'Alphakind';
  src: local('Alphakind'), url(./assets/fonts/Alphakind.ttf) format('truetype');
}

* {
  font-family: 'Alphakind' !important;
  letter-spacing: 1px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overall-container {
  top: 112px;
  position: fixed;
  width: 100%;
  height: calc(100% - 112px);
}

.internal-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 60px 0;
}

@media screen and (min-width: 640px) {
  .internal-container {
    width: 640px;
    padding: 60px 0;
  }
}

@media screen and (min-width: 980px) {
  .internal-container {
    width: 960px;
    padding: 80px 0;
  }
}

@media screen and (min-width: 1300px) {
  .internal-container {
    width: 1280px;
    padding: 100px 0;
  }
}

@media screen and (min-width: 1600px) {
  .internal-container {
    width: 1280px;
    padding: 120px 0;
  }
}
